<template>
  <section id="hero" style="margin-bottom: 5px;">
    <router-link to="/contacto" style="text-decoration: none;">
      <v-img
        :min-height="minHeight"
        :src="require('@/assets/herous.jpg')"
        class="white--text"
        gradient="to right, rgba(5, 11, 31, .8), rgba(5, 11, 31, .8)"
      >
        <v-container class="fill-height px-4 py-12">
          <v-responsive
            class="d-flex align-center"
            height="100%"
            max-width="700"
            width="100%"
          >
            <base-heading title="Solicita tu presupuesto" />
            <base-body>
              Comunícate con nuestro soporte técnico para más información.
            </base-body>

            <div
              :class="
                $vuetify.breakpoint.smAndDown
                  ? 'flex-column align-start'
                  : 'align-center'
              "
              class="d-flex flex-wrap"
            />
          </v-responsive>
        </v-container>
      </v-img>
    </router-link>
  </section>
</template>

<script>
export default {
  name: "SectionHero",

  provide: {
    theme: { isDark: true }
  },

  computed: {
    minHeight() {
      const height = this.$vuetify.breakpoint.mdAndUp ? "100vh" : "50vh";

      return `calc(${height} - ${this.$vuetify.application.top}px)`;
    }
  }
};
</script>

<style scoped></style>
